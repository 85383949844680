.template-section::-webkit-scrollbar {
  display: none !important;
}

.template-section {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}

.accordion-button:not(.collapsed) {
  color: #000098;
}

.accordion-button::after {
  background-image: url(https://www.svgrepo.com/show/74592/right-arrow.svg);
  fill: #021a75;
  position: absolute;
  left: -10px;
  transform: scale(0.65);
}

.accordion-button:not(.collapsed)::after {
  background-image: url(https://www.svgrepo.com/show/74592/right-arrow.svg);
  transform: rotate(90deg) scale(0.65);
}